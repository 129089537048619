// eslint-disable-next-line import/no-cycle
import router from '@routes/parents.router';
import Vue from 'vue';
import { gmapApi } from 'vue2-google-maps';
import { addArrayToDictionary } from '@utils';
import getSearchDescription from '@utils/getSearchDescription';
import {
  fetchSearchHistory,
  saveSearch,
  fetchSearch,
  deleteSearch,
  fetchPhilosophies,
  fetchMotivation,
  findFacilities,
} from './search.service';

function getDate({ value, date }) {
  if (value === 'future' && date) {
    return new Date(date);
  }
  return new Date();
}

const initialFilterState = {
  onlyEmergencyCare: false,
  availability: false, // boolean
  budget: undefined, // number
  age: {
    dependents: [], // ids array
    range: [], // string array
  },
  startDate: {
    value: '', // literal
    date: undefined, // date
  },
  hasSameScheduleNeeds: null,
  scheduleNeeds: {},
  facilityClass: '', // literal
  philosophies: [], // string array
};

const initialLocationState = {
  preciseLocation: false,
  lat: 37.7749,
  lon: -122.4376,
  targetAddress: 'San Francisco, CA',
  state: 'ca',
  city: 'sanfrancisco',
};

export default {
  namespaced: true,
  state: {
    filters: initialFilterState,
    comments: {},
    location: initialLocationState,
    history: [],
    philosophies: [],
    motivation: [],
    searchRadius: 10,
    pagination: {
      page: 1,
      perPage: 30,
      totalRecords: undefined,
    },
    loading: false,

    isInitialized: false,
  },

  getters: {
    getMostRecent: (state) => (state.history.length ? state.history[0] : null),

    totalUsed(state) {
      const getValue = (arr) => arr.reduce((sum, filter) => {
        if (Array.isArray(filter)) {
          return sum + Number(!!filter.length);
        }

        if (typeof filter === 'object') {
          return getValue(Object.values(filter));
        }

        return sum + Number(!!filter);
      }, 0);

      const { filters } = state;

      return getValue([
        filters.onlyEmergencyCare,
        filters.budget,
        filters.facilityClass,
        filters.philosophies,
      ]);
    },

    getFetchMarkersParams({
      location, filters, searchRadius, pagination,
    }, getters, rootState, rootGetters) {
      const sortOption = () => {
        if (rootGetters.showShortlist) {
          return rootState.facilities.shortlistSortOption;
        }
        if (rootGetters.showMatches) {
          return rootState.facilities.matchesSortOption;
        }
        return rootState.facilities.searchSortOption;
      };

      return {
        location,
        bounds: rootState.map.mapViewport.bounds,
        filters,
        pagination,
        geoSearchType: rootState.map.geoSearchType,
        sort: sortOption(),
        searchId: getters.getMostRecent?.id,
        searchRadius,
      };
    },

    motivationAsArray({ motivation }) {
      return Object.values(motivation);
    },

    searchTitle({ filters }, g, rootState) {
      return getSearchDescription.age(
        {
          dependents: filters.age.dependents,
        },
        {
          dependents: rootState.dependents.list || [],
        },
        {
          dependents: 'Your child care search for ',
        },
        'firstName',
      ) || 'Your child care search';
    },

    totalRecordsCount: (state) => state.pagination.totalRecords || 0,

    initialStateForScheduleNeeds(state) {
      const initialState = {};

      if (state.filters.age.dependents) {
        state.filters.age.dependents.forEach((id) => {
          initialState[id] = {
            monday: { from: null, to: null, checked: false },
            tuesday: { from: null, to: null, checked: false },
            wednesday: { from: null, to: null, checked: false },
            thursday: { from: null, to: null, checked: false },
            friday: { from: null, to: null, checked: false },
          };
        });
      }
      return initialState;
    },
  },

  mutations: {
    nextPage: (state) => {
      if (state.pagination.page < state.pagination.totalPages) {
        state.pagination.page += 1;
      }
    },

    prevPage: (state) => {
      if (state.pagination.page > 1) {
        state.pagination.page -= 1;
      }
    },

    firstPage: (state) => {
      state.pagination.page = 1;
    },

    updatePagination: (state, payload) => {
      state.pagination = { ...state.pagination, ...payload };
    },

    setSearchHistory: (state, searchesArr) => {
      state.history = searchesArr;
    },

    addSearchToHistory: (state, search) => {
      if (state.history.length < 1 || search.id !== state.history[0].id) {
        state.history = [search, ...state.history];
      }
    },

    loading: (state, payload) => {
      state.loading = payload;
    },

    setLocation: (state, locationObj) => {
      state.location = locationObj;
    },

    setSearchRadius: (state, radius) => {
      state.searchRadius = radius;
    },

    setFilters: (state, filtersObj) => {
      state.filters = { ...initialFilterState, ...filtersObj };
    },

    setPhilosophies: (state, philosophies) => {
      state.philosophies = philosophies;
    },

    setMotivation: (state, motivation) => {
      state.motivation = addArrayToDictionary(motivation, {});
    },

    deleteSearchById: (state, id) => {
      state.history = state.history.filter((search) => search.id !== id);
    },

    setInitialized: (state) => {
      state.isInitialized = true;
    },

    setDependents: (state, dependents) => {
      state.filters.age.dependents = dependents;
    },
  },

  actions: {
    async fetchSearchHistory({ commit, dispatch }) {
      try {
        const history = await fetchSearchHistory();
        commit('setSearchHistory', history);
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not fetch search history', error }, { root: true });
      }
    },

    async applyMostRecentSearch({ dispatch, state }) {
      await dispatch('fetchSearchHistory');
      if (!state.history.length) {
        return undefined;
      }
      return dispatch('applySearchById', state.history[0].id);
    },

    async initSearch({ dispatch, commit, state }, reload) {
      if (state.isInitialized) {
        return;
      }
      await dispatch('applyMostRecentSearch');
      if (reload) {
        dispatch('reload');
      }
      commit('setInitialized');
    },

    async setLocationByURL({ state, dispatch }) {
      const { city, state: locState } = router.currentRoute.params;
      if (!city && !state) {
        return;
      }
      await Vue.$gmapApiPromiseLazy();
      const google = gmapApi();
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ address: `${city}, ${locState}`, componentRestrictions: { country: 'US' } }, async (records) => {
        const result = records[0];
        if (!result) return;

        const { formatted_address: formattedAddress, geometry } = result;
        const targetAddress = formattedAddress.endsWith(', USA') ? formattedAddress.slice(0, -5) : formattedAddress;
        const location = {
          lat: geometry.location.lat(),
          lon: geometry.location.lng(),
          targetAddress,
          city,
          state: locState,
        };
        await dispatch('runSearch', { filters: state.filters, location });
      });
    },

    // search modal setup
    async fetchPhilosophies({ commit, dispatch, state }) {
      try {
        if (state.philosophies.length) {
          return;
        }
        const philosophies = await fetchPhilosophies();
        commit('setPhilosophies', philosophies);
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not fetch philosophies', error }, { root: true });
      }
    },

    // async fetchAgeRanges({ commit, dispatch, state }) {
    //   try {
    //     if (state.ageRanges.length) {
    //       return;
    //     }
    //     const ranges = await fetchAgeRanges();
    //     commit('setAgeRanges', ranges);
    //   } catch (error) {
    //     dispatch('notifications/addToastError',
    // { text: 'Could not fetch age ranges', error }, { root: true });
    //   }
    // },

    async fetchMotivation({
      commit, dispatch, getters,
    }) {
      try {
        if (getters.motivationAsArray.length) {
          return;
        }
        const motivation = await fetchMotivation();
        await commit('setMotivation', motivation);
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not fetch motivations', error }, { root: true });
      }
    },

    // run search
    async runSearch({ dispatch }, search) {
      if (search) {
        await dispatch('updateSearch', search);
      }
      await dispatch('saveActiveSearch');
      dispatch('reload');
    },

    async updateSearch({
      dispatch, commit, rootState, state, rootGetters,
    }, {
      pagination, location, filters, bounds, searchRadius, mixpanelEvent,
    } = {}) {
      commit('updatePagination', {
        page: pagination?.page || 1,
      });

      if (location) {
        dispatch('setLocation', location);
      }

      if (filters) {
        if (filters.age.dependents?.length) {
          await dispatch('isInitialized', undefined, { root: true });
          const userDeps = Object.keys(rootState.dependents.list).map(Number);
          const filteredDeps = filters.age.dependents
            .map(Number)
            .filter((id) => userDeps.includes(id));
          // eslint-disable-next-line no-param-reassign
          filters.age.dependents = filteredDeps;
        }
        commit('setFilters', { ...filters });
      }

      if (bounds) {
        commit('map/setViewportBounds', bounds, { root: true });
        commit('map/setRecordsBounds', bounds, { root: true });
      }

      if (searchRadius !== undefined) {
        commit('setSearchRadius', searchRadius);
      }

      if (mixpanelEvent) {
        const { targetAddress, preciseLocation, ...mixpanelLocation } = location || state.location;
        const event = state.history.length ? 'search_profile_updated' : 'search_profile_created';
        const startDate = getDate(mixpanelEvent.attributes.startDate);
        dispatch('track/event', {
          category: 'parents',
          event,
          props: {
            profile_version: '2021-06',
            ...mixpanelEvent,
            attributes: {
              ...mixpanelEvent.attributes,
              startDate,
              ...mixpanelLocation,
              searchRadius: searchRadius === undefined ? state.searchRadius : searchRadius,
            },
          },
        }, { root: true });
      }

      if (rootGetters.showRecords) {
      // eslint-disable-next-line no-underscore-dangle
        this._vm.$sync.queryWithState();
      }
    },

    setLocation: ({ commit }, location) => {
      commit('setLocation', location);
    },

    async saveActiveSearch({
      dispatch, state, commit, rootState,
    }) {
      try {
        const params = {
          filters: state.filters,
          location: state.location,
          bounds: rootState.map.mapViewport.bounds,
          searchRadius: state.searchRadius,
        };
        const search = await saveSearch(params);
        commit('addSearchToHistory', search);
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not save search', error }, { root: true });
      }
    },

    // TODO: rename this to be more clear in it's function: it runs the search query
    async reload({
      commit, dispatch, rootGetters,
    }) {
      try {
        await dispatch('isInitialized', undefined, { root: true });
        // TODO: this might belong somewhere better, but the scroll position
        // should be reset every time the search is "reloaded"
        document.documentElement.scrollTop = 0;

        commit('loading', true);
        const query = {
          ...rootGetters['search/getFetchMarkersParams'],
        };

        const response = await findFacilities(query);

        if (response) {
          const {
            facilities, orderedIds: ids, pagination,
          } = response;

          commit('updatePagination', pagination);
          commit('facilities/setFacilityRecords', { facilities, ids }, { root: true });
          // eslint-disable-next-line no-underscore-dangle
          this._vm.$sync.queryWithState();
          dispatch('map/setBounds', response.boundsToFit, { root: true });
        }

        commit('loading', false);
      } catch (error) {
        commit('loading', false);
        dispatch('notifications/addToastError', { text: 'Could not load the list of markers', error }, { root: true });
      }
    },

    // manage search history
    async applySearchById({ dispatch }, id) {
      try {
        const search = await fetchSearch(id);
        await dispatch('updateSearch', search);
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not apply search', error }, { root: true });
      }
    },

    async deleteSearchById({ commit, dispatch, state }, id) {
      try {
        await deleteSearch();
        commit('deleteSearchById', id);
      } catch (error) {
        dispatch('notifications/addToastError', { text: `Could not delete search:${state.history.find((item) => item.id === id).displayName}`, error }, { root: true });
      }
    },

    // pagination
    async nextPage({ dispatch, commit }) {
      await commit('nextPage');
      await dispatch('reload');
    },

    async prevPage({ dispatch, commit }) {
      await commit('prevPage');
      await dispatch('reload');
    },
  },
};
